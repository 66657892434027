.Button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
  min-width: 100px;
  cursor: pointer;
  opacity: none;
  border-color: var(--bokamera-grey-300);
  background: var(--bokamera-btn-bg);
  color: var(--bokamera-grey-800);

  &:hover {
    background-color: var(--bokamera-btn-bg-active);
    border-color: var(--bokamera-grey-400);
  }
  &:disabled {
    opacity: 0.8;
    cursor: auto;
    color: #ccc;
  }
  &--primary {
    background-color: var(--bokamera-primary);
    color: #fff;
  }
  &--primary&:hover {
    background-color: var(--bokamera-primary-active);
  }
  &--primary&--outline {
    background: none;
    background-color: none;
    border: 1px solid var(--bokamera-primary);
    color: var(--bokamera-primary);
  }
  &--primary&--outline:hover {
    background-color: var(--bokamera-primary);
    color: #fff;
    opacity: 1;
  }
  &--block {
    display: block;
    width: 100%;
  }
}
